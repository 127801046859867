html {
  font-size: 10px;
}

body {
  margin: 0;
  font-family: 'Noto Sans HK', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

* {
  box-sizing: border-box;
  text-underline-offset: 2px;
}

* {
  -webkit-tap-highlight-color: transparent;
}

input, textarea {
  -webkit-tap-highlight-color: unset;
}

/* Hide Edge password input reveal icon */
input::-ms-reveal,
input::-ms-clear {
  display: none;
}



ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}


#order-tracking-map {
  height: 100%;
}
